import { Category, ApiAction } from '@aws-amplify/core/internals/utils';
import { InternalGraphQLAPIClass } from './internals/InternalGraphQLAPI';
export const graphqlOperation = (query, variables = {}, authToken) => ({
    query,
    variables,
    authToken,
});
/**
 * Export Cloud Logic APIs
 */
export class GraphQLAPIClass extends InternalGraphQLAPIClass {
    getModuleName() {
        return 'GraphQLAPI';
    }
    /**
     * Executes a GraphQL operation
     *
     * @param options - GraphQL Options
     * @param [additionalHeaders] - headers to merge in after any `graphql_headers` set in the config
     * @returns An Observable if the query is a subscription query, else a promise of the graphql result.
     */
    graphql(amplify, options, additionalHeaders) {
        return super.graphql(amplify, options, additionalHeaders, {
            category: Category.API,
            action: ApiAction.GraphQl,
        });
    }
    /**
     * Checks to see if an error thrown is from an api request cancellation
     * @param error - Any error
     * @returns A boolean indicating if the error was from an api request cancellation
     */
    isCancelError(error) {
        return super.isCancelError(error);
    }
    /**
     * Cancels an inflight request. Only applicable for graphql queries and mutations
     * @param {any} request - request to cancel
     * @returns A boolean indicating if the request was cancelled
     */
    cancel(request, message) {
        return super.cancel(request, message);
    }
}
export const GraphQLAPI = new GraphQLAPIClass();
