// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { CanceledError } from '../errors';
import { parseRestApiServiceError } from './serviceError';
import { logger } from './logger';
/**
 * @internal
 */
export function createCancellableOperation(handler, abortController) {
    const isInternalPost = (handler) => !!abortController;
    // For creating a cancellable operation for public REST APIs, we need to create an AbortController
    // internally. Whereas for internal POST APIs, we need to accept in the AbortController from the
    // callers.
    const publicApisAbortController = new AbortController();
    const publicApisAbortSignal = publicApisAbortController.signal;
    const internalPostAbortSignal = abortController?.signal;
    const job = async () => {
        try {
            const response = await (isInternalPost(handler)
                ? handler()
                : handler(publicApisAbortSignal));
            if (response.statusCode >= 300) {
                throw await parseRestApiServiceError(response);
            }
            return response;
        }
        catch (error) {
            const abortSignal = internalPostAbortSignal ?? publicApisAbortSignal;
            if (error.name === 'AbortError' || abortSignal?.aborted === true) {
                const canceledError = new CanceledError({
                    ...(abortSignal.reason ? { message: abortSignal.reason } : undefined),
                    underlyingError: error,
                });
                logger.debug(error);
                throw canceledError;
            }
            logger.debug(error);
            throw error;
        }
    };
    if (isInternalPost(handler)) {
        return job();
    }
    else {
        const cancel = (abortMessage) => {
            if (publicApisAbortSignal.aborted === true) {
                return;
            }
            publicApisAbortController.abort(abortMessage);
            // Abort reason is not widely support enough across runtimes and and browsers, so we set it
            // if it is not already set.
            if (abortMessage && publicApisAbortSignal.reason !== abortMessage) {
                publicApisAbortSignal['reason'] =
                    abortMessage;
            }
        };
        return { response: job(), cancel };
    }
}
